.auth_container {
	width: 100%;
	background-color: var(--neutral-10);
	text-align: center;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.auth_style_white__3gZ3u .light {
	width: 100%;
}

.auth_card {
	width: 100%;
	max-width: 552px;
	border: 1px solid var(--border-stoke);
	border-radius: 8px;
	/* height: fit-content; */
	position: relative;
}

.auth_card > div:first-of-type img {
	cursor: pointer;
}

.auth_main_header {
	font-size: 1.5rem !important;
	color: var(--text-color);
	line-height: 32px;
	margin-top: 2rem;
}

.auth_sub_header {
	font-size: 0.875rem;
	color: var(--text-color);
	line-height: 20px;
	margin-top: 1rem;
}

.auth_input_placeholder::placeholder {
	color: var(--neutral-80);
	font-size: .875rem;
}

.christmasLightContainer {
	position: absolute;
	z-index: 20;
	top: -30px;
	left: 0;
	width: 100%;
}

.auth_label {
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
}

.link_class {
	font-size: 1rem;
	background-color: transparent;
	color: var(--primary);
	font-weight: 450;
	text-decoration: none !important;
}

.logo {
	width: 89px;
	margin: auto;
}

.logo img {
	width: 100%;
}

.CTA_container {
	width: 100%;
	max-width: 552px;
}

.hostel_icon {
	width: 1.5rem;
	height: 1.5rem;
}

.otp_input_style {
	border: 1px solid var(--border-stoke);
	border-radius: 4px;
	height: 3.7rem;
	width: 3.7rem !important;
}

.full {
	width: 100% !important;
}

.background {
	width: 100%;
	position: relative;
	height: 100vh;
	background-color: var(--primary);
}

.white {
	background-color: var(--white-color);
}

.imageContainer {
	width: 45%;
	background-color: var(--primary);
	background: url("../../assets/images/lines.png");
	background-size: contain;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.inner_image_container {
	max-width: 454px;
	position: relative;
	width: 100%;
	border-radius: 32px;
	background: linear-gradient(
		179deg,
		rgba(252, 252, 252, 0.2) 0.52%,
		rgba(252, 252, 252, 0) 106.54%
	);
	padding: 48px;
}
.bubbles {
	position: absolute;
	top: -20px;
	right: -20px;
	animation: bounce 2s ease-in-out infinite;
}

.welcome_circle {
	position: absolute;
	bottom: -40px;
	left: -40px;
	animation-name: spin;
	animation-duration: 10000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-origin: center;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes bounce {
	0% {
		transform: translateY(-30px);
	}

	50% {
		transform: translateY(0px);
		/* width: -100px;
		height: +100px; */
	}

	100% {
		transform: translateY(-30px);
	}
}

.inner_image_container h4 {
	color: var(--white-color);
	padding-bottom: 20px;
	font-size: 1.71rem;
	line-height: 32px;
	margin-top: 20px;
}

.inner_inner_image_container {
	width: 356px;
	height: 384px;
	border-radius: 24px;
	overflow: hidden;
}

.inner_inner_image_container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.background img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	flex: 3;
}

.forgot_login_content {
	width: 100%;
}

.otp_login_content {
	width: 100%;
}

/* new Auth background wrapper style */

.background {
	display: flex;
	height: 100vh;
}

.white {
	background-color: var(--white-color);
	flex: 1;
}

.background img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	flex: 3;
}

@media (max-width: 1200px) {
	.imageContainer {
		display: none;
	}
}

@media (max-width: 768px) {
	.light {
		top: -15px;
	}

	.auth_sub_header {
		margin-top: 0px;
		padding: 0 1.2rem;
	}

	.form_wrapper {
		padding: 0 1.2rem 0.7rem 1.2rem !important;
	}

	.form_footer_wrapper {
		padding: 0.5rem 1.2rem !important;
	}

	.background {
		height: 100%;
		padding-bottom: 100px;
		background-color: transparent;
	}

	/* .white {
		padding: 100px;
	} */
}
