.modal_wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	background: #fff;
	overflow: auto;
	border-radius: 4px;
	outline: none;
	padding: 0px;
	max-width: 90%;
	width: fit-content;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.modal_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 24px;
	height: 56px;
	border-radius: 1px 1px 0px 0px;
	border-bottom: 2px solid #d6dee9;
}

.rating_modal_body {
	padding: 30px 40px;
}

.rating_title {
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0px;
	color: #091e42;
	margin-bottom: 10px;
}

.rating_subtitle {
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0px;
	color: #42526e;
	margin-bottom: 12px;
}

.rating_label {
	font-size: 16px;
	letter-spacing: 0px;
	color: #253858;
	margin-bottom: 6px !important;
	font-weight: 400;
	line-height: 26.4px;
}

.rating_textarea {
	border: 1px solid #dfe1e6;
	padding: 10px 12px;
	color: #172b4d;
	font-weight: 450;
	font-size: 14px;
	line-height: 20.02px;
	letter-spacing: 0px;
	border-radius: 4px;
	height: 137px;
	outline: none;
	box-shadow: none !important;
}

.img_container {
	display: flex;
	justify-content: center;
	flex-grow: 1;
}

.img_container > img {
	width: 210px;
	height: 48px;
	object-fit: contain;
}

.star_wrapper {
	display: flex;
	align-items: center;
	gap: 58px;
	margin: 30px 0;
}

.star_item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	width: 90px;
	cursor: pointer;
}

.star_item_text {
	font-weight: 450;
	font-size: 14px;
	line-height: 20.02px;
	letter-spacing: 0px;
	color: #8993a4;
}

@media (max-width: 768px) {
	.rating_modal_body {
		padding: 24px 18px;
		max-height: calc(94vh - 126px);
		overflow-y: scroll;
	}

	.star_wrapper {
		flex-wrap: wrap;
		gap: 36px 30px;
	}

	.rating_title {
		font-size: 20px;
	}

	.img_container > img {
		width: 180px;
		height: 42px;
	}

	.modal_header {
		height: 48px;
	}

	.modal_wrapper {
		max-height: 94vh;
	}
}
